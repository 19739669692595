/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, css, Styled } from "theme-ui"
import Bio from "gatsby-theme-blog/src/components/bio"

const rootPath = `${__PATH_PREFIX__}/`

const Title = ({ children, location }) => (
    <Styled.h1
    css={css({
        my: 0,
        fontSize: 4,
    })}
    >
    <Styled.a
        as={Link}
        css={css({
        // color: `white`,
        boxShadow: `none`,
        textDecoration: `none`,
        fontWeight: 500,// reduce from 700, which was too much
        })}
        to={`/`}
    >
        {children}
    </Styled.a>
    </Styled.h1>
)

const Header = ({ children, title, ...props }) => (
  <header>
    <div
      css={css({
        maxWidth: `container`,
        mx: `auto`,
        px: 3,
        pt: 4,
      })}
    >
      <div
        css={css({
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          mb: 4,
        })}
      >
        <Title {...props}>{title}</Title>
        {children}
      </div>
      {props.location.pathname === rootPath && <Bio />}
    </div>
  </header>
)

export default Header
